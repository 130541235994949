<script>
    export default{
        props: {
            items:{
                type: Array,
                default: () => []
            },
            itemText:{
                type: String,
                default: () => ''
            },
            itemValue: {
                type: String | Number | null,
                default: () => null
            },
            value:{
                type: Array,
                default: () => []
            }

        },
        emits: ['input'],
        data() {
            return {
                options: [],
                draggedIndex: null,
                dragOverIndex: null,
                touchY: null
            }
        },
        watch: {
            value: function (newValue) {
                const itemMap = new Map(this.items.map(item => [item[this.itemValue], item]));
                this.options = newValue
                    .map(id => itemMap.get(id))
                    .filter(item => item !== undefined);

            }

        },
        methods: {
            onDragStart(index){
                this.draggedIndex = index;
            },
            onDragOver(index) {
                this.dragOverIndex = index;
            },
            onDragLeave() {
                this.dragOverIndex = null;
            },
            onDrop(targetIndex) {
                this.swapItems(targetIndex);
            },

            onTouchStart(index, event) {
                this.draggedIndex = index;
                this.touchY = event.touches[0].clientY; // Guarda la posición inicial del toque
            },
            onTouchMove(event) {
                if (this.draggedIndex === null) return;

                let currentY = event.touches[0].clientY;
                let difference = currentY - this.touchY;

                // Detectar si el movimiento es hacia arriba o abajo
                if (difference > 20) {
                    this.dragOverIndex = this.draggedIndex + 1;
                } else if (difference < -20) {
                    this.dragOverIndex = this.draggedIndex - 1;
                }
            },
            onTouchEnd() {
                if (this.dragOverIndex !== null) {
                    this.swapItems(this.dragOverIndex);
                }
            },

            // Método para intercambiar los elementos
            swapItems(targetIndex) {
                if (this.draggedIndex === null || targetIndex < 0 || targetIndex >= this.options.length) return;

                const itemsCopy = [...this.options];
                const itemsGragged = itemsCopy.splice(this.draggedIndex, 1); // remove Item and store it in new array
                const movedItem = itemsGragged[0];
                itemsCopy.splice(targetIndex, 0, movedItem);

                this.options = itemsCopy;
                this.draggedIndex = null;
                this.dragOverIndex = null;
                this.touchY = null;

                this.$emit('input', itemsCopy.map( item => item[this.itemValue]) );
            }
        },
        mounted(){
            const itemMap = new Map(this.items.map(item => [item[this.itemValue], item]));
            this.options = this.value
                .map(id => itemMap.get(id))
                .filter(item => item !== undefined);
            
            
            
        }
    }
</script>

<template>
    <div class="select-drop-container">
        <div class="select-drop">
            <template v-if="options.length > 0">
                <div class="pb-2">
                    <h4>
                        Lista de opciones seleccionadas
                    </h4>
                </div>
                <div
                    class="select-drop-option" 
                    v-for="(item, index) in options"
                    :key="index"
                    :class="{
                        'dragging': draggedIndex === index,
                        'drag-over': dragOverIndex === index
                    }"
                    :selected="item === itemValue"
                    :value="item[itemValue]"
                    draggable="true"
                    @dragstart="onDragStart(index)"
                    @dragover.prevent="onDragOver(index)"
                    @dragleave="onDragLeave"
                    @drop="onDrop(index)"
                    @touchstart="onTouchStart(index, $event)"
                    @touchmove="onTouchMove($event)"
                    @touchend="onTouchEnd"
                    >
                    <span class="select-drop-index">{{ index+1 }}</span>  {{ item[itemText] }}
                </div>
            </template>
            <div v-else class="no-options" >
                <p class="m-0"> No tiene opciones seleccionadas </p>
            </div>
        </div>

    </div>
</template>

<style scoped>
    .select-drop-container{
        width: 100%;
        /* width: 300px; */
        margin: auto;
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: .35rem;
    }
    .select-drop{
        padding: .5rem;
    }
    .select-drop-index{
        margin-right: .5rem;
    }
    .select-drop-option{
        padding: .25rem .75rem;
        margin: 5px 0;
        border: 1px solid #ccc;
        cursor: grab;
        transition: transform 0.2s ease, background 0.2s ease;
    }
    .select-drop-option:hover{
        background-color: #f0f0f0;
        color: #333;
    }

  

    .select-drop-option.dragging {
        background: #f0f0f0;
        transform: scale(1.05);
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        opacity: 0.7;
        cursor: grabbing;
    }

    /* Cuando un elemento está sobre otro */
    .select-drop-option.drag-over {
        background: #d4edda;
        border: 2px dashed #28a745;
        /* border-top: 2px dashed #28a745; */
    }

    .no-options{
        display: grid;
        place-items: center;
        padding: .25rem .75rem;
        margin: 5px 0;
    }
    .no-options p{
        margin: 0;
    }

</style>